



.client__avatar {
    width: 12rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    /* margin: 0 auto 1rem; */
    margin: auto;
    margin-bottom: 2rem;
    
    /* z-index: 3; */
    border: 0.4rem solid var(--color-primary-variant);
}




.testimonials__container {
    background-color: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    z-index: 2;
    border-radius: 0.2rem;
    /* user-select: none; */
    box-shadow: 0 0 32px 0 var(--color-green);
    position: relative;
    
}

.client__review {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
    margin-bottom: 2rem;
}

.client__intro {
    font-weight: bold;
}






/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media screen and (max-width: 1024px) {
    .container.testimonials__container {
        width: 60%;
    }
}


/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */

@media screen and (max-width: 600px) {
    .container.testimonials__container {
        width: var(--container-width-sm);
    }

    .client__review {
        width: var(--container-width-sm);

    }
}