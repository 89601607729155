.services__container {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2rem;
}

.service {
    padding: 1px;
    border-radius: 0.2rem;
    
    transition: var(--transition);
    z-index: 2;

    background:
    linear-gradient(to right, var(--color-green) 1px, transparent 1px) 0 0,
    linear-gradient(to right, var(--color-green) 1px, transparent 1px) 0 100%,
    linear-gradient(to left, var(--color-green) 1px, transparent 1px) 100% 0,
    linear-gradient(to left, var(--color-green) 1px, transparent 1px) 100% 100%,
    linear-gradient(to bottom, var(--color-green) 1px, transparent 1px) 0 0,
    linear-gradient(to bottom, var(--color-green) 1px, transparent 1px) 100% 0,
    linear-gradient(to top, var(--color-green) 1px, transparent 1px) 0 100%,
    linear-gradient(to top, var(--color-green) 1px, transparent 1px) 100% 100%;
    background-size: 20px 50%;
  background-repeat: no-repeat;
}


.service__wrapper {
    background-color: var(--color-bg);
    padding: 2.4rem 1.5rem;
    height: 100%;
}


.product__design {
    background-size: 50% 50% !important;
    background-repeat: no-repeat;
    box-shadow: 0 0 32px 0 var(--color-green);
}

.service:hover {
    background-size: 50% 50%;
  background-repeat: no-repeat;
  box-shadow: 0 0 32px 0 var(--color-green);
}

.service h3 {
    margin-bottom: 1rem;
}


.service small {
    /* font-size: 0.7rem; */
    color: var(--color-light);
}





/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media screen and (max-width: 1024px) {
    .services__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .service {
        height: auto;
    }
}


/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */

@media screen and (max-width: 600px) {
    .services__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
}