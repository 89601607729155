@import url('https://fonts.googleapis.com/css2?family=Smooch+Sans:wght@300&display=swap');

*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root{
    --color-bg: #0C1226;
    --color-bg-variant: #0E2331;
    --color-primary: #669DF6;
    --color-primary-variant: rgba(77,181,255,0.4);
    --color-white: #fff;
    --color-green: #129986;
    --color-green-light: #0EE6B7;
    --color-light: rgba(255,255,255,0.6);
    
    --transition:all 400ms ease;
    
    --container-width-lg:75%;
    --container-width-md:86%;
    --container-width-sm:90%;


}

html{
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display: none;
}

body {
    font-family: 'Anonymous Pro', monospace;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
}


/* ============== GENERAL STYLES =========== */

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1,h2,h3,h4,h5 {
    font-weight: 500;
}

h1{
    font-size: 3.5rem;
}

@media screen and (min-width: 1030px){

    section {
        padding-top: 8rem;
        

    }
}

.section-title {
    width: 100%;
    display: flex;
    justify-content: center;
}




.section-title h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
    width: fit-content;
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    border-bottom-width: 3px;
    border-style: solid;
    border-image: linear-gradient(to right, var(--color-primary-variant) 0%,var(--color-primary-variant) 33%,var(--color-primary) 33%,var(--color-primary) 66%,var(--color-primary-variant) 66%,var(--color-primary-variant) 100%) 1;
 
}





.text-light{
    color: var(--color-light);
}
.text-purple {
    color: #be64b6;
}

.text-green {
    color: #0EE6B7;
}

.text-blue {
    color: #55a1df;
}

.text-grey {
    color: #6f7586;
}

a {
    color: var(--color-primary);
    transition: var(--transition)
}

a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-white);
    padding: 0.65rem 1.2rem;
    border-radius: 0.2rem;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid var(--color-white);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-green);
    color: var(--color-white);
    border: 1px solid transparent;
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}


/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 4rem;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */

@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }
    section {
        padding-top: 2rem;
        

    }

    section > h2{
        margin-bottom: 2rem;
    }
}