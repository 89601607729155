footer {
    background: var(--color-bg-variant);
    z-index: 5 !important;
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
    position: relative;
}




footer a {
    color: var(--color-light);
}

.footer__logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: block;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

.footer__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}

.footer__socials a {
    background: var(--color-bg);
    color: var(--color-white);
    font-size: large;
    padding: 0.4rem;
    border-radius: 0.3rem;
    display: flex;
    border: 1px solid transparent;
    transition: var(--transition);
}

.footer__socials a:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: var(--color-bg);
}


.footer__copyright {
    margin-bottom: 4rem;
    color: var(--color-white);
}
