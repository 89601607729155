.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__item-wrapper {
    padding: 1px;
    border-radius: 0.2rem;
    
    transition: var(--transition);
    z-index: 2;

    background:
    linear-gradient(to right, var(--color-green) 1px, transparent 1px) 0 0,
    linear-gradient(to right, var(--color-green) 1px, transparent 1px) 0 100%,
    linear-gradient(to left, var(--color-green) 1px, transparent 1px) 100% 0,
    linear-gradient(to left, var(--color-green) 1px, transparent 1px) 100% 100%,
    linear-gradient(to bottom, var(--color-green) 1px, transparent 1px) 0 0,
    linear-gradient(to bottom, var(--color-green) 1px, transparent 1px) 100% 0,
    linear-gradient(to top, var(--color-green) 1px, transparent 1px) 0 100%,
    linear-gradient(to top, var(--color-green) 1px, transparent 1px) 100% 100%;
    background-size: 20px 50%;
  background-repeat: no-repeat;
}

.contact__options {
    display: flex;
    
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
    

    background-color: var(--color-bg);
    /* padding: 2.4rem 1.5rem; */
    /* height: 100%; */
}


.contact__label {
    color: var(--color-light);
}

.email__wrapper {
    background-size: 50% 50% !important;
  background-repeat: no-repeat;
  box-shadow: 0 0 32px 0 var(--color-green);
}


.contact__item-wrapper:hover {
    background-size: 50% 50%;
  background-repeat: no-repeat;
  box-shadow: 0 0 32px 0 var(--color-green);
}


.dot__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}



.dot{
    
    width:50px;
    height:50px;
    background:var(--color-bg-variant);
    border-radius:50%;
    
    box-shadow: 0 0 32px 0 var(--color-green);
    transition: var(--transition);

    
    
}




.dot .contact__option-icon {
    height: 40px;
    color: var(--color-white);
    margin-top: 7px;
    /* font-size: 20px; */
}

.dot:hover {
    background: var(--color-white);

}



.dot:hover .contact__option-icon{
    color: var(--color-bg);
    

}



.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    margin-top: 0.47rem;
    display: inline-block;
    font-size: 0.8rem;
    color: var(--color-white);
}

/* ============= FORM ================ */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    
}

input, textarea {
    width: 100%;
    padding: 2rem;
    border-radius: 0.2rem;
    background: var(--color-bg);
    border: 1px solid rgba(34,183,143,.5);
    resize: none;
    color: var(--color-white);
    z-index: 2;
}

input:focus, textarea:focus {
    border-color: var(--color-green);
    box-shadow: 0 0 32px 0 var(--color-green);
}


button {
    margin-top: 1.5rem;
}


/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media screen and (max-width: 1024px) {
    .contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */

@media screen and (max-width: 600px) {
    .contact__container {
        width: var(--container-width-sm);
        
    }
}