.experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;

}

.experience__wrapper {
    background-color: var(--color-bg);
    padding: 2.4rem 1.5rem;
    height: 100%;
}

.experience__container > div {
    
    padding: 1px;
    border-radius: 0.2rem;
    
    transition: var(--transition);
    z-index: 2;

    background:
    linear-gradient(to right, var(--color-green) 1px, transparent 1px) 0 0,
    linear-gradient(to right, var(--color-green) 1px, transparent 1px) 0 100%,
    linear-gradient(to left, var(--color-green) 1px, transparent 1px) 100% 0,
    linear-gradient(to left, var(--color-green) 1px, transparent 1px) 100% 100%,
    linear-gradient(to bottom, var(--color-green) 1px, transparent 1px) 0 0,
    linear-gradient(to bottom, var(--color-green) 1px, transparent 1px) 100% 0,
    linear-gradient(to top, var(--color-green) 1px, transparent 1px) 0 100%,
    linear-gradient(to top, var(--color-green) 1px, transparent 1px) 100% 100%;
    background-size: 20px 50%;
  background-repeat: no-repeat;
}




.experience__frontend {
    background-size: 50% 50% !important;
  background-repeat: no-repeat;
  box-shadow: 0 0 32px 0 var(--color-green);
}


.experience__container > div:hover {
    /* background: var(--color-bg);
    cursor: default; */

    
    background-size: 50% 50%;
  background-repeat: no-repeat;
  box-shadow: 0 0 32px 0 var(--color-green);

}

.experience__item {
    width: 100%;
}

.progress {
    margin-top: 1rem;
}

.experience__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary)
}

.experience__content {
    display: grid;
    grid-template-columns: 1fr;
}

.experience__details {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
}

.experience__details-icon {
    margin-top: 6px;
    color: var(--color-primary)
}



/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media screen and (max-width: 1024px) {
    .experience__container {
        grid-template-columns: 1fr;
    }

    .experience__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience__content {
        padding: 1rem;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */

@media screen and (max-width: 600px) {
    .experience__container {
        gap: 1rem;
    }

    .experience__container > div {
        width: 100%;
        padding: 2rem 1rem;
    }
}