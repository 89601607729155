header {
    /* height: 100vh; */
    padding-top: 15rem;
    /* overflow: hidden; */
}

.header__container {
    display: grid;
    grid-template-columns: 40% 50%;
    gap: 10%;
    

}

.header__content {
    /* display: flex; */
    height: fit-content;
    display: inline-block;
    align-self: flex-end;
    z-index: 1;
    
}

.blink {
    animation: blinker 1s step-start infinite;
    color: var(--color-green);
    font-weight: bold;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }






/* ============ CTA ============== */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}



/* ============ HEADER SOCIALS ============== */


.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 3rem;
    bottom: 3rem;
}

.header__socials a{
    /* color: var(--color-green); */
}


.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}


/* ============ ME ============== */

.me {
    background: linear-gradient(var(--color-bg-variant), transparent);
    width: 100%;
    border-radius: 0.5rem;
    margin-top: 4rem; 
    font-size: large;
    white-space: nowrap;
    box-shadow: 0 0 32px 0 var(--color-green);
    padding: 1.5rem;
}

.typed-out{
    overflow: hidden;
    /* border-right: .15em solid orange; */
    white-space: nowrap;
    animation: typing 5s forwards;
    font-size: 1.6rem;
    width: 0;
  }
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }

.me-image{
    /* padding-bottom: 4rem; */
    display: flex;
    justify-content: end;
    place-items: end;
    position: relative;
    
    
    
    /* aspect-ratio: 1/1; */
    
}




.me-image  img {
    width: 70%;
    object-fit: contain;
    z-index: 2;
    position: absolute;
    bottom: 0;
    right: 0;
    
}





.inner {
    height: 90%;
    aspect-ratio: 1/1;
    z-index: 1;
    position: absolute;
    bottom: 0;
    right: 0;
}

.inner1 {
    height: 100%;
    width: 100%;
    margin-right: -20%;
    margin-bottom: 20px;
    border-radius: 50%;
    border: 2px solid var(--color-bg-variant);
    box-shadow: 0 0 32px 0 var(--color-green);
    /* background-color: red; */
    position: absolute;
    bottom: 0;
    right: 0;

}


.dot{
    position:relative;
    top:0;
    left:0;
    width:50px;
    height:50px;
    background:var(--color-bg-variant);
    border-radius:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 32px 0 var(--color-green);
    transition: var(--transition);
    
}




.dot a {
    margin-top: 7px;
    margin-left: 3px;
    color: var(--color-white);
    font-size: 20px;
}

.dot:hover {
    background: var(--color-white);

}



.dot:hover a{
    color: var(--color-bg);
    

}




.inner1 .dot:nth-child(1) {
    transform: rotate(8deg) translate(300px);
}

.inner1 .dot:nth-child(1) a{
    transform: rotate(-10deg);
    }

.inner1 .dot:nth-child(2) {
transform: rotate(18deg) translate(360px);
}

.inner1 .dot:nth-child(2) a{
    transform: rotate(-20deg);
    }

.inner1 .dot:nth-child(3) {
    transform: rotate(30deg) translate(345px);
    }


    .inner1 .dot:nth-child(3) a{
        transform: rotate(-32deg);   
    }
  






/* ============ SCROLL DOWN ============== */


.scroll__down {
    position: absolute;
    right: 1rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}


/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */


@media screen and (max-width: 1500px){
    .inner {
        height: 70%;
    }
    
.inner1 .dot:nth-child(1) {
    transform: rotate(8deg) translate(230px);
}

.inner1 .dot:nth-child(1) a{
    transform: rotate(-10deg);
    }

.inner1 .dot:nth-child(2) {
transform: rotate(14deg) translate(265px);
}

.inner1 .dot:nth-child(2) a{
    transform: rotate(-16deg);
    }

.inner1 .dot:nth-child(3) {
    transform: rotate(24deg) translate(250px);
    }


    .inner1 .dot:nth-child(3) a{
        transform: rotate(-24deg);   
    }
  
}


@media screen and (max-width: 1200px){
    .inner {
        height: 60%;
    }
    .inner1 .dot:nth-child(1) {
        transform: rotate(8deg) translate(200px);
    }
    
    .inner1 .dot:nth-child(1) a{
        transform: rotate(-10deg);
        }
    
    .inner1 .dot:nth-child(2) {
    transform: rotate(12deg) translate(222px);
    }
    
    .inner1 .dot:nth-child(2) a{
        transform: rotate(-14deg);
        }
    
    .inner1 .dot:nth-child(3) {
        transform: rotate(18deg) translate(205px);
        }
    
    
        .inner1 .dot:nth-child(3) a{
            transform: rotate(-20deg);   
        }
}

@media screen and (max-width: 1290px) {
    .header__container {
        grid-template-columns: 50% 50%;
        gap: 0;
        
    
    }
}

@media screen and (max-width: 900px) {


    header {
        /* height: 100vh; */
        padding-top: 5rem;
        /* overflow: hidden; */
    }

    .header__container {
        display: grid;
        grid-template-columns: 1fr;
        
    }
    

    .header__content {
        width: 80%;
        margin: 2rem auto 4rem;
        
    }

    .me {
        width: 100%;
        font-size: small;
    }

    
    
    .me-image {
        display: none;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */

@media screen and (max-width: 600px) {
    .header__content {
        width: 100%;
        
    }

    .header__socials,
    .scroll__down {
        display: none;
    }
}


















