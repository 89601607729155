.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    /* padding: 1.3rem; */
    border-radius: 0.2rem;
    border: 1px solid rgba(34,183,143,.5);
    transition: var(--transition);
    z-index: 2;
}

.portfolio__item:hover {
    border-color: var(--color-green);
    background: var(--color-bg);
    box-shadow: 0 0 32px 0 var(--color-green);

}

.portfolio__item-image {
    /* border-radius: 1.5rem; */
    overflow: hidden;
    
}

.porfolio__item-content {
    padding: 1.3rem;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}



/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */

@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}

